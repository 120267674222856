import { Solution } from '@energiebespaarders/constants';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { ContactCategory, ContactSubject } from '~/types/graphql-global-types';
import roofImg from '/public/img/illustrations/cutouts/dakisolatie.jpg';
import glazingImg from '/public/img/illustrations/cutouts/isolatieglas.jpg';
import wallImg from '/public/img/illustrations/cutouts/spouwmuurisolatie.jpg';
import boilerImg from '/public/img/illustrations/cutouts/verwarming.jpg';
import floorImg from '/public/img/illustrations/cutouts/vloerisolatie.jpg';
import pvImg from '/public/img/illustrations/cutouts/zonnepanelen.jpg';

/** Used on server side, when window/document is inaccessible */
export const FALLBACK_WINDOW_HEIGHT = 800;

export const termsAndConditionsPdfs: Record<string, string> = {
  '2023-11':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2011-2023.pdf',
  '2023-03':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2003-2023.pdf',
  '2022-12':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2012-2022.pdf',
  '2022-05':
    'https://storage.googleapis.com/deb-documents/Algemene%20Voorwaarden%20Consumenten%20-%20De%20Energiebespaarders%2005-2022.pdf',
};

export const SOLUTION_ILLUSTRATIONS: Record<Solution, string> = {
  pvSystem: pvImg,
  wallInsulation: wallImg,
  crawlspaceInsulation: floorImg,
  floorInsulation: floorImg,
  innerRoofInsulation: roofImg,
  windowGlazing: glazingImg,
  hybridHeatPump: boilerImg,
  electricHeatPump: boilerImg,
  airToAirHeatPump: boilerImg,
  centralHeatingBoiler: boilerImg,
  atticFloorInsulation: roofImg,
  windowFrames: glazingImg,
  miscellaneous: '',
  sedum: '',
  chargingBox: '',
  underfloorHeating: '',
  homeBattery: '',
};

export const STATUSES_NL: { [x: string]: string } = {
  sent: 'Verstuurd',
  accepted: 'Akkoord',
  cancelled: 'Geannuleerd',
  paid: 'Betaald',
  opened: 'Geopend',
  all: 'Alles',
};

export enum PartnerKey {
  ABN = 'abn',
  ASR = 'asr',
  AEGON = 'aegon',
  BudgetThuis = 'budgetThuis',
  Consumentenbond = 'consumentenbond',
  DAK = 'dak',
  EasySwitch = 'easySwitch',
  EBP = 'ebp',
  Ennatuurlijk = 'ennatuurlijk',
  FinancieelFit = 'financieelFit',
  FinancieelZeker = 'financieelZeker',
  Finzie = 'finzie',
  Florius = 'florius',
  Frits = 'frits',
  GavoorA = 'gavoora',
  GroeneHart = 'groeneHart',
  HpHypotheken = 'hpHypotheken',
  HuisHypotheek = 'huisHypotheek',
  Hypokeur = 'hypokeur',
  Hypotheek24 = 'hypotheek24',
  HypotheekBerekenen = 'hypotheekberekenen',
  Hypotheekshop = 'hypotheekshop',
  HypotheekVisie = 'hypotheekVisie',
  ImpactHypotheken = 'impactHypotheken',
  Lot = 'lot',
  Mangotree = 'mangotree',
  MUNT = 'munt',
  NBG = 'nbg',
  NIBC = 'nibc',
  Overstappen = 'overstappen',
  Promodomo = 'promodomo',
  Pwc = 'pwc',
  Syntrus = 'syntrus',
  Triodos = 'triodos',
  VanBruggen = 'vanbruggen',
  VanLanschot = 'vanlanschot',
  VanLanschotKempen = 'vanlanschotkempen',
  Veldsink = 'veldsink',
  Venn = 'venn',
  Verbrugge = 'verbrugge',
  Verder = 'verder',
  Viisi = 'viisi',
  Vista = 'vista',
}

export type PartnerRoute = { path: string; partner?: PartnerKey };

// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
// IF YOU EDIT THIS MAKE SURE THAT YOU UPDATE THE ROUTES CONFIG IN routes.js (at the top)
// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
/** The homepage-lookalike pages */
export const partnerLandingRoutes: PartnerRoute[] = [
  { path: '/florius', partner: PartnerKey.Florius },
  { path: '/gavoora', partner: PartnerKey.GavoorA },
  { path: '/huis-hypotheek', partner: PartnerKey.HuisHypotheek },
  { path: '/hypokeur', partner: PartnerKey.Hypokeur },
  { path: '/hypotheekberekenen', partner: PartnerKey.HypotheekBerekenen },
  { path: '/hypotheekshop', partner: PartnerKey.Hypotheekshop },
  { path: '/nbg', partner: PartnerKey.NBG },
  { path: '/triodos', partner: PartnerKey.Triodos },
  { path: '/viisi', partner: PartnerKey.Viisi },
];

/** The version with minimal nav and a request form */
export const partnerLandingPagesWithRequestForm: PartnerRoute[] = [
  { path: '/asr-klanten', partner: PartnerKey.ASR },
  { path: '/asr', partner: PartnerKey.ASR },
  { path: '/easyswitch', partner: PartnerKey.EasySwitch },
  { path: '/actiehypotheekadvies', partner: PartnerKey.Consumentenbond },
  { path: '/frits', partner: PartnerKey.Frits },
  { path: '/hypotheekvisie', partner: PartnerKey.HypotheekVisie },
  { path: '/munthypotheken', partner: PartnerKey.MUNT },
  { path: '/overstappen', partner: PartnerKey.Overstappen },
  { path: '/promodomo', partner: PartnerKey.Promodomo },
  { path: '/vanlanschotkempen', partner: PartnerKey.VanLanschotKempen },
  { path: '/veldsink', partner: PartnerKey.Veldsink },
  { path: '/vennhypotheken', partner: PartnerKey.Venn },
  { path: '/vistahypotheken', partner: PartnerKey.Vista },
];

// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
// IF YOU EDIT THIS MAKE SURE THAT YOU UPDATE THE ROUTE CONFIG IN routes.js  (at the top)
// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
export const mortgageLandingRoutes: PartnerRoute[] = [
  { path: '/cmis', partner: undefined },
  { path: '/floriusadviseur', partner: PartnerKey.Florius },
  { path: '/nbgadviseur', partner: PartnerKey.NBG },
  { path: '/triodosadviseur', partner: PartnerKey.Triodos },
  { path: '/veldsinkadviseur', partner: PartnerKey.Veldsink },
];

// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
// IF YOU EDIT THIS MAKE SURE THAT YOU UPDATE THE ROUTE CONFIG IN routes.js (at the top)
// ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
export const partnerLandingPages = [
  ...partnerLandingRoutes.map(route => route.path),
  '/veldsink',
  '/veldsinkadvies',
];

export const mortgageLandingPages = mortgageLandingRoutes.map(route => route.path);

export const triodosLandingPages = ['/triodos', '/triodosadviseur'];

export const veldsinkLandingPages = [
  '/veldsink',
  '/veldsinkadvies',
  '/veldsinkadviseur',
  '/collegavanveldsink',
];

export const floriusLandingPages = [
  '/florius',
  '/floriusadviseur',
  '/florius-actie',
  '/florius-verduurzaamadvies',
];

export const nbgLandingPages = ['/nbg', '/nbgadviseur'];

export const categoryOptions: DropdownOption<ContactCategory>[] = [
  {
    label: 'Ik heb een vraag',
    value: ContactCategory.question,
  },
  {
    label: 'Ik heb feedback',
    value: ContactCategory.feedback,
  },
  {
    label: 'Ik heb een technisch probleem',
    value: ContactCategory.technicalIssue,
  },
  {
    label: 'Ik heb een klacht',
    value: ContactCategory.complaint,
  },
  {
    label: 'Ik wil samenwerken met Energiebespaarders',
    value: ContactCategory.partnership,
  },
];

export const contactSubjects: Record<ContactCategory, DropdownOption<ContactSubject>[]> = {
  question: [
    {
      label: 'Over onze dienstverlening',
      value: ContactSubject.generalServiceQuestion,
    },
    {
      label: 'Over mijn advies of offertes',
      value: ContactSubject.adviceQuestion,
    },
    {
      label: 'Over mijn installatieplanning',
      value: ContactSubject.planningQuestion,
    },
    {
      label: 'Over het energielabel',
      value: ContactSubject.energyLabelQuestion,
    },
    {
      label: 'Over subsidies',
      value: ContactSubject.subsidyQuestion,
    },
    {
      label: 'Anders',
      value: ContactSubject.otherQuestion,
    },
  ],
  technicalIssue: [
    {
      label: 'Met mijn account',
      value: ContactSubject.accountIssue,
    },
    {
      label: 'Met e-mails en berichtgeving',
      value: ContactSubject.emailIssue,
    },
    {
      label: 'Anders',
      value: ContactSubject.otherIssue,
    },
  ],
  feedback: [
    {
      label: 'Over onze website',
      value: ContactSubject.websiteFeedback,
    },
    {
      label: 'Over onze dienstverlening',
      value: ContactSubject.serviceFeedback,
    },
    {
      label: 'Over een product of installatie',
      value: ContactSubject.installationFeedback,
    },
    {
      label: 'Anders',
      value: ContactSubject.otherFeedback,
    },
  ],
  complaint: [
    {
      label: 'Algemeen',
      value: ContactSubject.generalComplaint,
    },
    {
      label: 'Over ons advies en ontzorging',
      value: ContactSubject.serviceComplaint,
    },
    {
      label: 'De uitvoer of installateur',
      value: ContactSubject.installerComplaint,
    },
  ],
  partnership: [
    {
      label: 'Als installatiepartner',
      value: ContactSubject.installerPartner,
    },
    { label: 'Als financieel adviseur of geldverstrekker', value: ContactSubject.financialPartner },
    {
      label: 'Anders',
      value: ContactSubject.otherPartner,
    },
  ],
};

/**
 * timestamped: vrijdag 6 januari 2023 om 15:22
 * full: vrijdag 6 januari 2023
 * medium: vr 6 jan 2023
 * short: 06-01-2023
 * shortest: 6-1-23
 */
export const dateStringFormatters = {
  timestamped: 'dddd D MMMM YYYY [om] HH:mm',
  yearless: 'dddd D MMMM',
  full: 'dddd D MMMM YYYY',
  medium: 'D MMMM YYYY',
  short: 'DD-MM-YYYY',
  shortest: 'd-M-YY',
} as const;
