import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import { isInvalidEmail, isMissing } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Flex, Input, TextLink } from '@energiebespaarders/symbols';
import { Book, Medium, Red, Small } from '@energiebespaarders/symbols/helpers';
import { Key, MagicWand, Mail } from '@energiebespaarders/symbols/icons/solid';
import { useRouter } from 'next/router';
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getActiveHouseId, identifyMe } from '~/components/_app/initializeSession';
import { MeCustomerFragment } from '~/fragments/Me';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import useGAEvent from '~/hooks/useGAEvent';
import { useMeOptional } from '~/hooks/useMe';
import { loginCustomer, loginCustomerVariables } from '~/types/generated/loginCustomer';
import { requestMagicLink, requestMagicLinkVariables } from '~/types/generated/requestMagicLink';
import { CustomerLoginError } from '~/types/graphql-global-types';
import { formatPhoneE164 } from '~/utils/formatPhoneNumber';
import { REQUEST_CUSTOMER_MAGIC_LINK } from '../MagicLink/RequestMagicLinkForm';

export const LOGIN_CUSTOMER = gql`
  ${MeCustomerFragment}
  mutation loginCustomer($email: String!, $password: String!) {
    loginCustomer(email: $email, password: $password) {
      ... on Customer {
        id
        ...MeCustomer
        created
      }
      ... on CustomerLoginErrorResult {
        error
      }
    }
  }
`;

interface LoginFormValues {
  email: string;
  password: string;
}
interface LoginFormProps {
  email: string;
  setEmail?: Dispatch<SetStateAction<string>>;
  showForgotForm?: () => void;
  onMagicLinkSent?: () => void;
  magicLinkRedirect?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
  email,
  setEmail,
  showForgotForm,
  onMagicLinkSent,
  magicLinkRedirect,
}) => {
  const { setMe } = useMeOptional();
  const { setActiveHouseId } = useActiveHouseId();
  const [loginError, setLoginError] = useState('');
  const [usingMagicLink, setUsingMagicLink] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const router = useRouter();
  const { from } = router.query as Record<string, string>;
  const sendGAEvent = useGAEvent();

  const [requestCustomerMagicLink, { loading: sendingLink, error: apolloErrorMagicLink }] =
    useMutation<requestMagicLink, requestMagicLinkVariables>(REQUEST_CUSTOMER_MAGIC_LINK, {
      onCompleted: () => {
        setShowConfirm(true);
        onMagicLinkSent?.();
      },
    });

  const [loginCustomerMutation, { loading: loggingIn, error: apolloError }] = useMutation<
    loginCustomer,
    loginCustomerVariables
  >(LOGIN_CUSTOMER, {
    onCompleted: async data => {
      if (data.loginCustomer.__typename === 'Customer') {
        const customer = data.loginCustomer;
        void sendGAEvent('login', {
          method: 'login_form',
          user_id: customer.id,
          user_type: customer.__typename,
          email: customer.email,
          name: `${customer.firstName} ${customer.lastName}`,
          phone: formatPhoneE164(customer.phone),
          intercom_hash: customer.intercomHash,
        });

        setMe(data.loginCustomer);
        const houseId = getActiveHouseId(data.loginCustomer);
        setActiveHouseId(houseId);
        return identifyMe(data.loginCustomer);
      }

      switch (data.loginCustomer.error) {
        case CustomerLoginError.NoPlatformAccess:
          setLoginError(
            'Uw aanvraag bij ons is via een externe partij gedaan. Er is daarom geen account bij ons voor u geactiveerd. Neem contact met hen op om door te gaan.',
          );
          break;
        case CustomerLoginError.MagicLinkExpired:
          setLoginError(
            'De link waarmee u probeert in te loggen is verlopen. Vraag een nieuwe aan.',
          );
          break;
        case CustomerLoginError.InvalidCredentials:
        case CustomerLoginError.Default:
        default:
          setLoginError('De combinatie van het e-mailadres en wachtwoord is niet correct.');
          break;
      }
    },
  });

  const { formState, handleChange, submitForm, submissionAttempted } = useForm<LoginFormValues>({
    blockEnterToSubmit: true, // handled by button type=submit, it'll do 2 submits otherwise.
    // TODO: for useForm: check if element is contained in form element with a handleSubmit callback, auto-block the additional enterToSubmit then?
    initialValues: {
      email,
      password: '',
    },
    handleSubmit: values => {
      if (usingMagicLink) {
        let path = 'mijn-verduurzaming';
        // redirect to where the user came from, e.g. landing on /mijn-offertes -> redirect to /login -> e-mail with magic link pointing to mijn-offertes
        if (from?.startsWith('/') && from?.length > 3) path = from.slice(1); // remove initial /
        if (magicLinkRedirect?.startsWith('/') && magicLinkRedirect?.length > 3) {
          path = magicLinkRedirect.slice(1); // remove initial /
        }
        return requestCustomerMagicLink({
          variables: { email: values.email, path },
        });
      }
      return loginCustomerMutation({ variables: values });
    },
    validate: (values, errors) => {
      if (isMissing(values.email)) errors.email = 'Ontbreekt';
      if (isInvalidEmail(values.email)) errors.email = 'Ongeldig e-mailadres';
      if (!usingMagicLink && isMissing(values.password)) errors.password = 'Ontbreekt';
      return errors;
    },
  });

  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail?.(e.target.value);
      handleChange({ email: e.target.value });
    },
    [handleChange, setEmail],
  );
  const handleChangePassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChange({ password: e.target.value });
    },
    [handleChange],
  );

  const renderGoToInbox = useCallback(() => {
    let inboxHref = '';
    if (email.includes('gmail.com')) inboxHref = 'gmail.com';
    if (email.includes('hotmail.com')) inboxHref = 'hotmail.com';
    return inboxHref ? (
      <Button
        href={`https://${inboxHref}`}
        target="_blank"
        rel="noopener noreferrer"
        title="Ga direct naar je inbox"
        iconStart={Mail}
        className="image-link"
      >
        Ga direct naar je inbox
      </Button>
    ) : (
      <></>
    );
  }, [email]);

  return showConfirm ? (
    <Box width={1} px={1} textAlign="center">
      <p>
        Als dat e-mailadres bij ons bekend is, wordt er een e-mail naar <Medium>{email}</Medium>{' '}
        verstuurd met een link om direct in te loggen.
      </p>
      {renderGoToInbox()}
    </Box>
  ) : (
    <form onSubmit={submitForm} style={{ width: '100%' }}>
      <Flex flexWrap="wrap" mx={-1} alignItems="flex-end">
        <Box width={[1, 1, 1 / 2]} px={1}>
          <Input
            type="email"
            name="email"
            label="E-mailadres"
            onChange={handleChangeEmail}
            icon={Mail}
            addonSide="start"
            value={formState.email.value}
            touched={formState.email.touched}
            error={formState.email.error}
            autoComplete="email"
            inputMode="email"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]} px={1}>
          {usingMagicLink ? (
            <Button
              key="submitMagicLinkRequest"
              type="submit"
              bgColor="yellow"
              color="grayBlack"
              label="Stuur me een link →"
              fluid
              loading={sendingLink}
              disabled={!formState.email.value}
              style={{ borderRadius: 3 }}
            />
          ) : (
            <Input
              type="password"
              name="password"
              icon={Key}
              addonSide="start"
              label="Wachtwoord"
              onChange={handleChangePassword}
              value={formState.password.value}
              touched={formState.password.touched}
              error={formState.password.error}
              data-private="redact"
              autoComplete="current-password"
            />
          )}
        </Box>
        <Box width={[1, 1, 1 / 2]} px={1}>
          <Button
            key="toggleLoginMethod"
            type="button"
            minimal
            fluid
            label={usingMagicLink ? 'Gebruik wachtwoord' : 'Gebruik magic link'}
            iconStart={usingMagicLink ? Key : MagicWand}
            mr={0}
            mb={0}
            bgColor="blue"
            onClick={() => setUsingMagicLink(v => !v)}
            style={{ borderRadius: 3 }}
          />
        </Box>
        {!usingMagicLink && (
          <Box width={[1, 1, 1 / 2]} px={1}>
            <Button
              key="submitLogin"
              type="submit"
              bgColor="yellow"
              color="grayBlack"
              fluid
              loading={loggingIn}
              label="Inloggen →"
              mb={0}
              disabled={!formState.email.value || !formState.password.value}
              style={{ borderRadius: 3 }}
            />
          </Box>
        )}
        {loginError && (
          <Box width={1} px={1} textAlign="center" mt={2}>
            <Small>
              <Red>{loginError}</Red>
              {showForgotForm && (
                <Box pt={1}>
                  <Book>
                    <TextLink onClick={showForgotForm}>
                      Ben je je wachtwoord vergeten of heb je er nog geen aangemaakt? Klik hier!
                    </TextLink>
                  </Book>
                </Box>
              )}
            </Small>
          </Box>
        )}
        {submissionAttempted && (apolloError || apolloErrorMagicLink) && (
          <Box width={1} px={1} textAlign="center" mt={2}>
            <Small>
              <Red>
                Er is iets misgegaan bij het inloggen. Ververs de pagina en probeer het opnieuw.
              </Red>
            </Small>
          </Box>
        )}
      </Flex>
    </form>
  );
};

export default LoginForm;
