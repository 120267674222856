// TODO: move these routes to a sub-path, e.g. /partner/:slug and /hypotheekpartner/:slug
// Then add redirects in next.config.js for backwards compatibility
// 16-01-2024: We can do this when we move these to DatoCMS.
var partnerRoutes = [
  '/florius',
  '/gavoora',
  '/huishypotheek',
  '/hypokeur',
  '/hypotheekberekenen',
  '/hypotheekshop',
  '/nbg',
  '/triodos',
  '/viisi',
];

var partnerRoutesVersionB = [
  '/asr-klanten',
  '/asr',
  '/easyswitch',
  '/actiehypotheekadvies',
  '/frits',
  '/hypotheekvisie',
  '/munthypotheken',
  '/overstappen',
  '/promodomo',
  '/vanlanschotkempen',
  '/veldsink',
  '/vennhypotheken',
  '/vistahypotheken',
];

/**
 * @typedef {{
 *  source: string,
 *  destination: string,
 *  auth?: 'customer' | 'partnerAgent' | 'operator',
 *  visitorOnly?: boolean, // only for customer or lead, not operator/PartnerAgent/InstallerAgent. Could maybe use 'auth: visitor' instead
 *  hideNav?: boolean,
 *  hideFooter?: boolean,
 * }} Route
 *
 * FYI generate fancy ASCII texts here https://patorjk.com/software/taag/#p=display&f=ANSI%20Shadow&t=TEST
 *
 * @type {Array<Route>}
 */
const routes = [
  {
    source: '/logout',
    destination: '/Logout',
  },
  {
    source: '/auth/google',
    destination: '/auth/google',
  },
  // ███████╗██╗   ██╗███╗   ██╗███╗   ██╗███████╗██╗
  // ██╔════╝██║   ██║████╗  ██║████╗  ██║██╔════╝██║
  // █████╗  ██║   ██║██╔██╗ ██║██╔██╗ ██║█████╗  ██║
  // ██╔══╝  ██║   ██║██║╚██╗██║██║╚██╗██║██╔══╝  ██║
  // ██║     ╚██████╔╝██║ ╚████║██║ ╚████║███████╗███████╗
  // ╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═══╝╚══════╝╚══════╝
  {
    source: '/',
    destination: '/HomePage',
    visitorOnly: true,
  },
  ...['/bespaarcheck', '/bespaarcheck-ebv'].map(source => ({
    source,
    destination: '/SavingsCheck',
    visitorOnly: true,
    useGradientBg: true,
  })),
  ...['/bespaarmogelijkheden', '/bespaarmogelijkheden-ebv'].map(source => ({
    source,
    destination: '/Results',
    visitorOnly: true,
    useGradientBg: true,
  })),
  {
    source: '/diensten',
    destination: '/RequestPackage',
    visitorOnly: true,
  },
  {
    source: '/diensten/:slug',
    destination: '/RequestPackage/:slug',
    visitorOnly: true,
  },
  {
    source: '/betaling',
    destination: '/Payment',
    useGradientBg: true,
  },
  {
    source: '/bedankt/:encodedParams',
    destination: '/ThanksRequest',
    visitorOnly: true,
    useGradientBg: true,
  },
  {
    source: '/intake',
    destination: '/SelfServiceIntake',
    hideNav: true,
    hideFooter: true,
    useGradientBg: true,
  },
  {
    source: '/intake/indicaties',
    destination: '/SelfServiceIntake/estimates',
    auth: 'customer',
    hideNav: true,
    hideFooter: true,
    useGradientBg: true,
  },
  {
    source: '/intake/inplannen',
    destination: '/SelfServiceIntake/appointment',
    auth: 'customer',
    hideNav: true,
    hideFooter: true,
    useGradientBg: true,
  },

  //   ██████╗ ██╗   ██╗ ██████╗ ████████╗███████╗    ███████╗██╗      ██████╗ ██╗    ██╗
  //  ██╔═══██╗██║   ██║██╔═══██╗╚══██╔══╝██╔════╝    ██╔════╝██║     ██╔═══██╗██║    ██║
  //  ██║   ██║██║   ██║██║   ██║   ██║   █████╗      █████╗  ██║     ██║   ██║██║ █╗ ██║
  //  ██║▄▄ ██║██║   ██║██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║     ██║   ██║██║███╗██║
  //  ╚██████╔╝╚██████╔╝╚██████╔╝   ██║   ███████╗    ██║     ███████╗╚██████╔╝╚███╔███╔╝
  //   ╚══▀▀═╝  ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚══════╝ ╚═════╝  ╚══╝╚══╝

  {
    source: '/prijsindicaties',
    destination: '/QuoteEstimates',
    hideNav: true,
    hideFooter: true,
    useGradientBg: true,
  },
  {
    source: '/belafspraak-offertes',
    destination: '/QuoteCallAppointment',
    hideNav: true,
    hideFooter: true,
    useGradientBg: true,
  },
  {
    source: '/offertes-aangevraagd',
    destination: '/ThanksQuotesOnly',
    useGradientBg: true,
  },

  //  ██████╗ ██████╗ ███╗   ██╗████████╗███████╗███╗   ██╗████████╗    ██████╗  █████╗  ██████╗ ███████╗███████╗
  // ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝    ██╔══██╗██╔══██╗██╔════╝ ██╔════╝██╔════╝
  // ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗  ██╔██╗ ██║   ██║       ██████╔╝███████║██║  ███╗█████╗  ███████╗
  // ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██║╚██╗██║   ██║       ██╔═══╝ ██╔══██║██║   ██║██╔══╝  ╚════██║
  // ╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██║ ╚████║   ██║       ██║     ██║  ██║╚██████╔╝███████╗███████║
  //  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝       ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝
  {
    source: '/woning-verduurzamen',
    destination: '/woning-verduurzamen',
    useGradientBg: true,
  },
  {
    source: '/over-ons',
    destination: '/AboutUs',
  },
  ...['/veelgestelde-vragen', '/faq', '/help'].map(source => ({
    source,
    destination: '/faq',
  })),
  ...['/veelgestelde-vragen/[...slugs]', '/faq/[...slugs]'].map(source => ({
    source,
    destination: '/faq/[...slugs]',
  })),
  {
    source: '/garantie',
    destination: '/Warranty',
    useGradientBg: true,
  },
  {
    source: '/csat/:slug/:encodedParams',
    destination: '/CSAT',
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/campagne/:slug',
    destination: '/campagne/:slug',
    visitorOnly: true,
  },
  {
    source: '/privacy',
    destination: '/Privacy',
  },
  {
    source: '/voorwaarden',
    destination: '/TermsAndConditions',
  },
  {
    source: '/offerte-isolatie',
    destination: '/RequestQuotes/insulation',
    hideNav: true,
  },
  {
    source: '/offerte-warmtepomp',
    destination: '/RequestQuotes/heatPump',
    hideNav: true,
  },
  {
    source: '/offerte-zonnepanelen',
    destination: '/RequestQuotes/pvSystem',
    hideNav: true,
  },
  {
    source: '/verduurzamingsplan',
    destination: '/RequestQuotes',
    hideNav: true,
  },
  {
    source: '/offerte-intake',
    destination: '/RequestQuotes/intake',
    hideNav: true,
    useGradientBg: true,
  },
  {
    source: '/klanten',
    destination: '/Testimonials',
    useGradientBg: true,
  },
  {
    source: '/klanten/:name',
    destination: '/Testimonials/:name',
  },
  {
    source: '/partner-worden',
    destination: '/InstallerSignUpLanding',
    hideNav: true,
    hideFooter: true,
  },

  //  █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗
  // ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝
  // ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║
  // ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║
  // ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║
  // ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝
  ...['/login', '/inloggen'].map(source => ({
    source,
    destination: '/LoginCustomer',
    visitorOnly: true,
    useGradientBg: true,
  })),
  ...['/wachtwoord-herstellen', '/wachtwoord-instellen'].map(source => ({
    source,
    destination: '/ResetPasswordCustomer',
    visitorOnly: true,
    useGradientBg: true,
  })),
  {
    source: '/ep-in-behandeling',
    destination: '/MyPassportInProgress',
    auth: 'customer',
    useGradientBg: true,
  },
  {
    source: '/mijn-energiepaspoort',
    destination: '/MyEnergyPassport',
    auth: 'customer',
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/mijn-verduurzaming',
    destination: '/MyProgress',
    auth: 'customer',
    useGradientBg: true,
  },
  {
    source: '/mijn-afspraken',
    destination: '/MyAppointments',
    auth: 'customer',
    useGradientBg: true,
  },
  {
    source: '/mijn-offertes',
    destination: '/MyQuotes',
    auth: 'customer',
    useGradientBg: true,
  },
  {
    source: '/mijn-offertes/:quoteId',
    destination: '/MyQuotes/:quoteId',
    auth: 'customer',
    useGradientBg: true,
    hideFooter: true,
  },
  {
    source: '/mijn-gegevens',
    destination: '/MyAccount',
    auth: 'customer',
    useGradientBg: true,
  },
  {
    source: '/opdracht-planning',
    destination: '/MyJobPlanning',
    auth: 'customer',
    useGradientBg: true,
  },
  {
    source: '/bespaarbuddies',
    destination: '/MyReferrals',
    auth: 'customer',
    useGradientBg: true,
  },

  //  ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗ ██████╗ ██████╗      ██████╗ ██████╗ ███╗   ██╗████████╗██████╗  ██████╗ ██╗
  // ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔═══██╗██╔══██╗    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔═══██╗██║
  // ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║   ██║██████╔╝    ██║     ██║   ██║██╔██╗ ██║   ██║   ██████╔╝██║   ██║██║
  // ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║   ██║██╔══██╗    ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██╗██║   ██║██║
  // ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ╚██████╔╝██║  ██║    ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║╚██████╔╝███████╗
  //  ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝     ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚══════╝
  {
    source: '/login-operator',
    destination: '/LoginOperator',
    // visitorOnly: true,
  },
  ...['/operator-control-panel', '/operator-control-panel/overview'].map(source => ({
    source,
    destination: '/OperatorControlPanel',
    auth: 'operator',
  })),
  {
    source: '/operator-control-panel/advice/:houseId',
    destination: '/OperatorControlPanel/Advice',
    auth: 'operator',
  },
  {
    source: '/operator-control-panel/quote/:quoteId',
    destination: '/OperatorControlPanel/Quote',
    auth: 'operator',
  },

  // ███╗   ███╗ █████╗ ██████╗ ██╗  ██╗███████╗████████╗██╗███╗   ██╗ ██████╗         ██╗     ██████╗ █████╗ ███╗   ███╗██████╗  █████╗ ██╗ ██████╗ ███╗   ██╗███████╗
  // ████╗ ████║██╔══██╗██╔══██╗██║ ██╔╝██╔════╝╚══██╔══╝██║████╗  ██║██╔════╝        ██╔╝    ██╔════╝██╔══██╗████╗ ████║██╔══██╗██╔══██╗██║██╔════╝ ████╗  ██║██╔════╝
  // ██╔████╔██║███████║██████╔╝█████╔╝ █████╗     ██║   ██║██╔██╗ ██║██║  ███╗      ██╔╝     ██║     ███████║██╔████╔██║██████╔╝███████║██║██║  ███╗██╔██╗ ██║███████╗
  // ██║╚██╔╝██║██╔══██║██╔══██╗██╔═██╗ ██╔══╝     ██║   ██║██║╚██╗██║██║   ██║     ██╔╝      ██║     ██╔══██║██║╚██╔╝██║██╔═══╝ ██╔══██║██║██║   ██║██║╚██╗██║╚════██║
  // ██║ ╚═╝ ██║██║  ██║██║  ██║██║  ██╗███████╗   ██║   ██║██║ ╚████║╚██████╔╝    ██╔╝       ╚██████╗██║  ██║██║ ╚═╝ ██║██║     ██║  ██║██║╚██████╔╝██║ ╚████║███████║
  // ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝     ╚═╝         ╚═════╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
  ...partnerRoutes.map(source => ({
    source,
    destination: '/PartnerLanding',
    visitorOnly: true,
  })),
  ...partnerRoutesVersionB.map(source => ({
    source,
    destination: '/PartnerLanding/versionB',
    visitorOnly: true,
    hideNav: true,
  })),
  {
    source: '/florius-verduurzaamadvies',
    destination: '/FloriusMortgageLanding',
    visitorOnly: true,
    hideFooter: true,
  },
  {
    source: '/vvaa',
    destination: '/ArtsEnAuto',
    visitorOnly: true,
    hideNav: true,
  },

  // ██████╗ ██╗   ██╗██╗██╗     ██████╗ ██╗███╗   ██╗ ██████╗     ██████╗ ██╗      ██████╗  ██████╗██╗  ██╗███████╗
  // ██╔══██╗██║   ██║██║██║     ██╔══██╗██║████╗  ██║██╔════╝     ██╔══██╗██║     ██╔═══██╗██╔════╝██║ ██╔╝██╔════╝
  // ██████╔╝██║   ██║██║██║     ██║  ██║██║██╔██╗ ██║██║  ███╗    ██████╔╝██║     ██║   ██║██║     █████╔╝ ███████╗
  // ██╔══██╗██║   ██║██║██║     ██║  ██║██║██║╚██╗██║██║   ██║    ██╔══██╗██║     ██║   ██║██║     ██╔═██╗ ╚════██║
  // ██████╔╝╚██████╔╝██║███████╗██████╔╝██║██║ ╚████║╚██████╔╝    ██████╔╝███████╗╚██████╔╝╚██████╗██║  ██╗███████║
  // ╚═════╝  ╚═════╝ ╚═╝╚══════╝╚═════╝ ╚═╝╚═╝  ╚═══╝ ╚═════╝     ╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝╚══════╝

  {
    source: '/druten',
    destination: '/BuildingBlocks/Druten',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/wijchen',
    destination: '/BuildingBlocks/Wijchen',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },

  // ██╗███████╗██████╗  █████╗ ███╗   ███╗███████╗
  // ██║██╔════╝██╔══██╗██╔══██╗████╗ ████║██╔════╝
  // ██║█████╗  ██████╔╝███████║██╔████╔██║█████╗
  // ██║██╔══╝  ██╔══██╗██╔══██║██║╚██╔╝██║██╔══╝
  // ██║██║     ██║  ██║██║  ██║██║ ╚═╝ ██║███████╗
  // ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝
  {
    source: '/iframe/bespaarcheck',
    destination: '/_iframe/FramedSavingsCheck',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/iframe/mogelijkheden',
    destination: '/_iframe/FramedResults',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/iframe/aanvraag',
    destination: '/_iframe/FramedRequestPackage',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/iframe/bedankt',
    destination: '/_iframe/FramedThanks',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },

  //   ███████╗ █████╗ ███╗   ███╗███████╗███╗   ██╗
  //   ██╔════╝██╔══██╗████╗ ████║██╔════╝████╗  ██║
  //   ███████╗███████║██╔████╔██║█████╗  ██╔██╗ ██║
  //   ╚════██║██╔══██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║
  //   ███████║██║  ██║██║ ╚═╝ ██║███████╗██║ ╚████║
  //   ╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝
  {
    source: '/dossierverzoek/:preDossierId',
    destination: '/Samen/PreDossierCheck',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },
  {
    source: '/samenwerken',
    destination: '/Samen/BecomeAPartner',
    visitorOnly: true,
  },
  {
    source: '/samen/login',
    destination: '/Samen/PartnerLogin',
    // visitorOnly: true,
    hideNav: true,
  },
  {
    source: '/samen/aanmelden',
    destination: '/Samen/PartnerRegister',
    visitorOnly: true,
    hideNav: true,
    hideFooter: true,
  },
  ...['/samen/activatie', '/samen/wachtwoordherstel'].map(source => ({
    source,
    destination: '/Samen/PartnerPasswordReset',
    visitorOnly: true,
  })),
  {
    source: '/samen/proefaanvraag',
    destination: '/Samen/NewPartnerRequest',
    visitorOnly: true,
  },
  ...['/samen', '/samen/start'].map(source => ({
    source,
    destination: '/Samen/PartnerHome',
    auth: 'partnerAgent',
  })),
  {
    source: '/samen/dossiers',
    destination: '/Samen/PartnerDossiers',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/profiel',
    destination: '/Samen/PartnerProfile',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/check/:houseId',
    destination: '/Samen/PartnerCheck',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/gva-scan/:houseId',
    destination: '/Samen/PartnerCheckExtended',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/mogelijkheden/:houseId',
    destination: '/Samen/PartnerResults',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/aanvraag',
    destination: '/Samen/PartnerRequest',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/aanvraag/:houseId',
    destination: '/Samen/PartnerRequest',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/snelaanvraag',
    destination: '/Samen/PartnerInstantRequest',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/inplannen/:houseId',
    destination: '/Samen/PartnerAdvicePlanning',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/dashboard',
    destination: '/Samen/PartnerDashboard',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/help',
    destination: '/Samen/PartnerHelp',
    auth: 'partnerAgent',
  },
  {
    source: '/samen/bedankt/:orderId',
    destination: '/Samen/PartnerThanks',
    auth: 'partnerAgent',
  },
  // ██████╗ ██╗      ██████╗  ██████╗
  // ██╔══██╗██║     ██╔═══██╗██╔════╝
  // ██████╔╝██║     ██║   ██║██║  ███╗
  // ██╔══██╗██║     ██║   ██║██║   ██║
  // ██████╔╝███████╗╚██████╔╝╚██████╔╝
  // ╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝
  {
    source: '/blog',
    destination: '/blog',
  },
  // █████╗  ██████╗ ██╗
  // ██╔══██╗██╔══██╗██║
  // ███████║██████╔╝██║
  // ██╔══██║██╔═══╝ ██║
  // ██║  ██║██║     ██║
  // ╚═╝  ╚═╝╚═╝     ╚═╝
  {
    source: '/robots.txt',
    destination: '/api/robots',
  },
  {
    source: '/sitemap.xml',
    destination: '/sitemap.xml.ts',
  },
  // { // TODO: add DatoCMS routes later, replace Content pages
  //   source: '/pages/_datocms/CMSRouter',
  //   destination: '/_datocms/CMSRouter',
  // },
  //
];

module.exports = {
  routes,
};
