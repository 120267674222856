import mediaMin, {
  breakpointsEm,
  breakpointsPx,
} from '@energiebespaarders/symbols/styles/breakpoints';
import { rgba, stripUnit } from 'polished';
import { css } from 'styled-components';
import { shade } from './mixins';
import theme from './theme';

const html = css`
  ::selection {
    background-color: ${rgba(theme.colors.purpleDark, 0.9)};
    color: white;
  }

  html {
    font-size: ${theme.type.fontSizeRoot};
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: ${theme.type.fontFamilyBody};
    font-size: ${theme.type.fontSizeBase};
    line-height: ${theme.type.lineHeightBase};
    color: ${theme.colors.grayBlack};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {
    margin: 0.5em 0;

    &:first-child {
      margin-top: 0;
    }
  }

  blockquote,
  p,
  ul,
  ol,
  a,
  q {
    line-height: 1.5;

    ${mediaMin.sm} {
      line-height: calc(
        1.5 + (${theme.type.lineHeightBase} - 1.5) * (100vw - ${breakpointsEm.sm}) /
          (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
      letter-spacing: calc(
        0 + (${stripUnit(theme.type.letterSpacingBase)} - -0.0125) * (100vw - ${breakpointsEm.sm}) /
          (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }

    ${mediaMin.lg} {
      line-height: ${theme.type.lineHeightBase};
      letter-spacing: ${theme.type.letterSpacingBase};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    line-height: 1.2;

    ${mediaMin.sm} {
      line-height: calc(
        1.2 + (${theme.type.lineHeightBase} - 1.2) * (100vw - ${breakpointsEm.sm}) /
          (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
      letter-spacing: calc(
        0 + (${stripUnit(theme.type.letterSpacingHeadings)} - -0.0125) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }

    ${mediaMin.lg} {
      line-height: 1.2;
      letter-spacing: ${theme.type.letterSpacingHeadings};
    }
  }

  h1,
  .h1 {
    font-size: ${theme.type.scale[2]};
    ${mediaMin.sm} {
      font-size: calc(
        (${theme.type.scale[2]}) +
          (${stripUnit(theme.type.scale[1])} - ${stripUnit(theme.type.scale[2])}) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }
    ${mediaMin.lg} {
      font-size: ${theme.type.scale[1]};
    }
  }

  h2,
  .h2 {
    font-size: ${theme.type.scale[3]};
    ${mediaMin.sm} {
      font-size: calc(
        (${theme.type.scale[3]}) +
          (${stripUnit(theme.type.scale[2])} - ${stripUnit(theme.type.scale[3])}) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }
    ${mediaMin.lg} {
      font-size: ${theme.type.scale[2]};
    }
  }

  h3,
  .h3 {
    font-size: ${theme.type.scale[4]};
    ${mediaMin.sm} {
      font-size: calc(
        (${theme.type.scale[4]}) +
          (${stripUnit(theme.type.scale[3])} - ${stripUnit(theme.type.scale[4])}) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }
    ${mediaMin.lg} {
      font-size: ${theme.type.scale[3]};
    }
  }

  h4,
  .h4 {
    font-size: ${theme.type.scale[5]};
    ${mediaMin.sm} {
      font-size: calc(
        (${theme.type.scale[5]}) +
          (${stripUnit(theme.type.scale[4])} - ${stripUnit(theme.type.scale[5])}) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }
    ${mediaMin.lg} {
      font-size: ${theme.type.scale[4]};
    }
  }

  h5,
  .h5,
  blockquote {
    font-size: ${theme.type.scale[6]};
    ${mediaMin.sm} {
      font-size: calc(
        (${theme.type.scale[6]}) +
          (${stripUnit(theme.type.scale[5])} - ${stripUnit(theme.type.scale[6])}) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }
    ${mediaMin.lg} {
      font-size: ${theme.type.scale[5]};
    }
  }

  h6,
  .h6 {
    font-size: ${theme.type.scale[7]};
    ${mediaMin.sm} {
      font-size: calc(
        (${theme.type.scale[7]}) +
          (${stripUnit(theme.type.scale[6])} - ${stripUnit(theme.type.scale[7])}) *
          (100vw - ${breakpointsEm.sm}) / (${breakpointsPx.lg} - ${breakpointsPx.sm})
      );
    }
    ${mediaMin.lg} {
      font-size: ${theme.type.scale[6]};
    }
  }

  p,
  ul,
  ol {
    font-size: 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-family: ${theme.type.fontFamilyHeadings};

    & + p {
      margin-top: 0;
    }

    p + & {
      margin-top: 0.75em;
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  form,
  fieldset,
  input,
  select,
  textarea {
    font-family: ${theme.type.fontFamilySans};
    background-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:disabled {
      background: ${theme.colors.grayLight};
      color: ${theme.colors.gray};
      pointer-events: none;
    }
  }

  a,
  button {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  button {
    font-family: ${theme.type.fontFamilySans};

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  a {
    color: ${theme.colors.green};

    &:hover,
    &:focus {
      color: ${shade(0.9, theme.colors.green)};
    }

    &:active {
      color: ${shade(0.8, theme.colors.green)};
    }
  }

  blockquote,
  q {
    font-family: ${theme.type.fontFamilySerif};
    font-weight: 400;
    font-style: italic;
  }

  q {
    quotes: '“' '”' '‘' '’';
  }

  u {
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-color: inherit;
  }

  mark {
    padding: 0 0.25em;
    box-decoration-break: clone;
  }

  hr {
    appearance: none;
    --webkit-margin-before: 0;
    --webkit-margin-after: 0;
  }
`;

export default html;

//  ██████╗███████╗███████╗    ██╗      ██████╗  ██████╗██╗  ██╗███████╗
// ██╔════╝██╔════╝██╔════╝    ██║     ██╔═══██╗██╔════╝██║ ██╔╝██╔════╝
// ██║     ███████╗███████╗    ██║     ██║   ██║██║     █████╔╝ ███████╗
// ██║     ╚════██║╚════██║    ██║     ██║   ██║██║     ██╔═██╗ ╚════██║
// ╚██████╗███████║███████║    ███████╗╚██████╔╝╚██████╗██║  ██╗███████║
//  ╚═════╝╚══════╝╚══════╝    ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝╚══════╝
//
// https://fvsch.com/code/css-locks/
//
//
// @media (min-width: 20em) and (max-width: 60em) {
//   selector {
//     property: calc(
//       baseline_value +
//       multiplier *
//       (100vw - 20rem) / (60 - 20)
//     );
//   }
// }
//
// Where multiplier is the expected total value increase in rem, without the unit.
