import { theme } from '@energiebespaarders/symbols';
import { css, DefaultTheme, ThemeColors } from 'styled-components';

export const colors: ThemeColors = {
  ...theme.colors,
  veldsink: {
    primary: '#519e19',
    secondary: '#ec8636',
  },
};

export const fixedSizes = {
  dropdownItemHeight: '50px',
  footerHeight: '480px',
  footerHeightMobile: '240px',
  navHeight: '70px',
  navMenuHeight: '300px',
  navWidthMobile: '290px',
  scrollbarWidth: '17px',
  subNavHeight: '50px',
};

export const chiliTheme: DefaultTheme = {
  ...theme,
  colors,
  fixedSizes,
};

const newTheme: DefaultTheme = {
  ...theme,
  fixedSizes,
  type: {
    ...theme.type,
    fontSizeRoot: '18px',
    fontSizeBase: '18px',
    fontFamilySerif: 'Lora, Merriweather, serif',
    fontFamilyBody: '"Causten Round", sans-serif',
    fontFamilyHeadings: 'Causten, sans-serif',
    fontFamilySans: '"Causten Round", sans-serif',
  },
  colors: {
    ...chiliTheme.colors,
  },
};

export default newTheme;

export const grayscaleCSSFilter = css`
  filter: url("data:image/svg+xml;utf8, <svg xmlns='https://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>%23grayscale");
  filter: gray;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
`;

export const resetCSSFilters = css`
  -webkit-filter: none !important;
  filter: none !important;
`;
